export const orginaztionPages = [
	{
		name: 'Home',
		page: '/page/home',
		status: 'Link',
	},
	{
		name: 'Teachers',
		page: '/page/teachers',
		status: 'Link',
	},
	{
		name: 'Students',
		page: '/page/students',
		status: 'Link',
	},
	{
		name: 'Campaigns',
		page: '/page/campaign',
		status: 'Link',
	},
];
