export const AdminPages = [
	{
		name: 'Organizations',
		page: '/admin/home',
		status: 'Link',
	},
	{
		name: 'Teachers',
		page: '/admin/teachers',
		status: 'Link',
	},
	{
		name: 'Students',
		page: '/admin/students',
		status: 'Link',
	},
	{
		name: 'Campaigns',
		page: '/admin/campaign',
		status: 'Link',
	},
];
