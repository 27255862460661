//#88A2C5
export const ThemeColor = {
	red: '#f55b6f',
	borwan: '#775c4d',
	gray: '#775c4d',
	black: '#000',
	grayLight: '#e7e7e7',
	greySecondary: '#fbfcfd',
	grayPlaceHolder: '#bac2cb',
	green: '#37B899',
	greenLight: '#e0f4ec',
	colorError: '#d23f3f',
	borderColor: '#e5e3e9',
	grayColor: '#666666',
	colorBadge: '#948f90',
	tableHeadColor: '#f2f2f2',
	paginationColor: '#b7b5b5',
	successColor: '#1a9b37',
	darkGreenColor: '#1a9b37',
	paleGreenColor: '#defded',
	orange: '#fda14d',
	darkyellow: '#f0c45c',
	navyblue: '#204474',
	navyBlueLight: '#88a1c8',
};
