import React, { lazy, Suspense } from 'react';
import './App.css';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';
import AlertComponent from './HOCS/alert';
import AuthContext from './Context/authContext';
import Loader from './HOCS/loader';
import ProtectedRoute from './HOCS/ProtectedRoute';
import { LoaderStyles } from './HOCS/loader.styles';
import ScrollToTop from './HOCS/scrollTop';
const Dashboard = React.lazy(() => import('./containers/PageRoute/pagesRoute'));
const NotFound = React.lazy(() => import('./containers/Not-Found/not-found'));
const Auth = React.lazy(() => import('./containers/Auth/auth'));
const Homepage = React.lazy(() => import('./containers/Home/home'));
const Howitworkspage = React.lazy(
	() => import('./containers/Howitworks/howitworks')
);
const Donationpage = React.lazy(
	() => import('./containers/DonationPage/donationPage')
);
const Confirmationmail = React.lazy(
	() => import('./containers/Confirmatoinmail/confirmationmail')
);
const Admin = React.lazy(() => import('./containers/Admin/adminroutes'));

function App() {
	return (
		<HashRouter>
			<AuthContext>
				<Suspense
					fallback={
						<>
							<LoaderStyles style={{ background: '#f8f9fa' }}>
								<div className='loader'></div>
							</LoaderStyles>
						</>
					}
				>
					<Loader>
						<AlertComponent></AlertComponent>
						<ScrollToTop />

						<Switch>
							<Route exact path={'/'} component={Homepage} />
							<Route path={'/howitworks'} component={Howitworkspage} />
							<Route path={'/donation'} component={Donationpage} />
							<Route
								path={'/confirmregistration'}
								component={Confirmationmail}
							/>

							<ProtectedRoute>
								<Route path={'/admin'} component={Admin} />
								<Route path={'/page'} component={Dashboard} />
								<Route path={'/auth'} component={Auth} />
							</ProtectedRoute>

							<Route component={NotFound} />
						</Switch>
					</Loader>
				</Suspense>
			</AuthContext>
		</HashRouter>
	);
}

export default App;
