export const TeacherPages = [
	{
		name: 'Home',
		page: '/page/home',
		status: 'Link',
	},
	{
		name: 'Manage My Students',
		page: '/page/students',
		status: 'Link',
	},
	{
		name: 'My Campaigns',
		page: '/page/campaign',
		status: 'Link',
	},
];
